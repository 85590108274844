import { lazy } from "react";

const CompanySettingsList = lazy(() => import("./companySettingsList"));
const LocationSubject = lazy(() => import("./locationSubject"));

const companySettingsRoutes = [
  {
    path: "/company-settings",
    element: <CompanySettingsList />,
  },
  {
    path: "/location-subject",
    element: <LocationSubject />,
  }
];

export default companySettingsRoutes;
