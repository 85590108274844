
import moment from "moment";
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import _ from "lodash";
import { NotificationContainer, NotificationManager, } from "react-notifications";
import { currencySymbol } from "./constant";
import { numberWithCommas } from "./utils";
const showMessage = (type, msg, title = null) => {
  switch (type) {
    case 'info':
      NotificationManager.info(msg, title);
      break;
    case 'success':
      NotificationManager.success(msg, title);
      break;
    case 'warning':
      NotificationManager.warning(msg, title);
      break;
    case 'error':
      NotificationManager.error(msg, title);
      break;
  }
}
const todayDate = (format = "DD-MM-YYYY") => {
  return moment().format(format);
}

const multipleIntVal = (val1, val2, rounded = true) => {
  let solution = rounded ? _.round(val1 * val2) : val1 * val2
  return solution
}

const dateConversion = (date, format = "DD-MM-YYYY") => {
  return date ? moment(new Date(date)).format(format) : "";
}

const capitalizeFirstLetter = (str) => {
  return str ? str[0].toUpperCase() + str.slice(1).toLowerCase() : str;
}

const numLetterSpace = (str) => {
  // str.match(/[a-zA-Z]+|[0-9]+/g);
  var re = /[^0-9](?=[0-9])/g;
  // var str = 'E2356<br/>E123-F456';
  var result = str.replace(re, '$& ');
  return result
  return str.replace(/([A-Z])(\d)/g, '$1 $2')
}

const timeConversion = (time, format = "HH:mm:ss") => {
  return time ? moment(time, "HH:mm:ss").format(format) : "";
}

const checkValue = (val) => {
  const emptyStr = "---"
  if (val == null || val == "" || val == undefined) {
    return emptyStr
  } else {
    return val
  }
}

const export2pdf = (headers, data, title, name) => {
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait";
  const marginLeft = 20;
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(15);
  let content = {
    startY: 50,
    head: headers,
    body: data
  };

  doc.text(title, marginLeft, 20);
  doc.autoTable(content);
  doc.save(`${name}.pdf`)
}
const dateCustomConversion = (date, format = "DD-MM-YYYY") => {
  return date ? moment(date, "DD-MM-YYYY hh:mm").format(format) : "";
}

const perviousYear = (times) => {
  let startYear = moment().subtract(times, 'year').format('YYYY')
  let yearList = _.times(times, i => (
    {
      "yearId": i + 1,
      "yearName": parseInt(startYear) + i + 1
    }
  ))
  return yearList
}

const numberList = (times) => {
  let numberList = _.times(times, i => (
    {
      "numberId": i + 1,
      "numberName": parseInt(i + 1)
    }
  ))
  return numberList
}
const formatStr = (string) => {
  const str = string.toLowerCase();
  return str.replace(/(\s\w)/g, m => m.toUpperCase()).replace(/\s+/g, '');
}

const floatFormat = (data) => {
  const amountData = data ? `${convertToDecimal(parseFloat(data))}` : `${convertToDecimal(parseFloat(0))}`;
  return numberWithCommas(amountData || 0)
}
const amountFormat = (data, currencyName = "dollar") => {
  const amountData = data ? `${currencySymbol[currencyName]} ${(parseFloat(data))}` : `${currencySymbol[currencyName]} ${(parseFloat(0))}`;
  return numberWithCommas(amountData || 0)
  return data ? `${currencySymbol[currencyName]} ${parseFloat(data).toFixed(2)}` : `${currencySymbol[currencyName]} ${parseFloat(0).toFixed(2)}`;
}

const convertToDecimal = (num) => {
  num = num.toString(); //If it's not already a String
  num = num.slice(0, (num.indexOf(".")) + 3); //With 3 exposing the hundredths place
  return Number(num)
//  alert('M : ' +  Number(num)); //If you need it back as a Number    
}

const subtractvalues = (val1, val2) => {
  return val1 && val2 ? parseFloat(val1) - parseFloat(val2) : "0"
}
const isEven = (n) => {
  return n % 2 == 0;
}
const capsFirst = (string) => {
  const str = string.toLowerCase();
  return str.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
}
const unChangeObg = (arr) => {
  return JSON.parse(JSON.stringify(arr))
}
const stringWithoutQuotes = (str) => {
  if (typeof (str) === 'object') {
    str = str.join(',')
  }
  let stringWithoutQuotes = str.replace(/"/g, '');
  return stringWithoutQuotes;
}

const removeNullKeysFromObj = (obj) => {
  let result = Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if (Array.isArray(value)) {
        return [key, value.filter(v => {
          return Object.keys(v).filter((o) => {
            return v[o] === null && delete v[o]
          })
        })];
      } else {
        return [key, value];
      }
    }).filter(([_, v]) => v !== null)
  );
  return result
}

const arrayWithQuotes = (str) => {
  let convertedNumbers = str.split(',').map(num => {
    return `'${num}'`;
  });
  const result = '[' + convertedNumbers.join(',') + ']'
  return result
}

const removeStrWhiteSpace = (str) => {
  return str.replaceAll(/\s/g, '')
}
export {
  showMessage,
  dateCustomConversion,
  todayDate,
  dateConversion,
  capitalizeFirstLetter,
  numLetterSpace,
  removeNullKeysFromObj,
  removeStrWhiteSpace,
  floatFormat,
  amountFormat,
  unChangeObg,
  multipleIntVal,
  arrayWithQuotes,
  numberList,
  stringWithoutQuotes,
  convertToDecimal,
  subtractvalues,
  perviousYear,
  timeConversion,
  export2pdf,
  checkValue,
  isEven,
  capsFirst,
  formatStr
};